.special {
  background-color: #f8f8f8;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "BMWTypeNext Latin", "PingFang SC", "Helvetica Neue", Helvetica,
    "Microsoft Yahei", sans-serif;
  text-align: center;
  .icon {
    width: 45rem;
  }
  .word{
    margin-top: 3.5rem;
    h3{
      font-size: 3.1rem;
      color: #1D68D5;
    }
    p{
      font-size: 1.1rem;
      margin: .6rem 0;
    }
    a{
      font-size: 1.7rem;
      text-decoration:none;
      color: #FF8802;
      font-weight: bold;
      &.mail{
        text-decoration: underline;
        font-size: 1.1rem;
        color: #1D68D5;
      }
    }
  }
}
