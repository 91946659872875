.unified {
  background-color: #f8f8f8;
  height: 100%;
  display: flex;
  font-family: "BMWTypeNext Latin", "PingFang SC", "Helvetica Neue", Helvetica,
    "Microsoft Yahei", sans-serif;
  font-size: 0.9rem;
  .login {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    .logo {
      position: absolute;
      width: 4.8rem;
      top: 30px;
      right: 60px;
      z-index: 1;
    }
    .left {
      width: 31%;
      height: auto;
    }
    // height: 655px;
    .box {
      width: 33%;
      border: 1px solid #e5e5e5;
      margin-right: 1rem;
      background-color: #fff;
      border-radius: 8px;
      text-align: center;
      padding: 3rem 3rem;
      position: relative;
      .api-info {
        font-size: 2.5rem;
        margin: 0.8rem 0 2.5rem;
        line-height: 3.4rem;
      }
      .provider {
        padding-bottom: 2rem;
        border-bottom: 1px solid #bbbbbb;
        margin-bottom: 1.8rem;
        h5 {
          text-align: left;
        }
        .pro-btn {
          width: 100%;
          height: 3.2rem;
          line-height: 3.2rem;
          font-weight: bold;
          color: #bbbbbb;
          border: 1px solid #bbbbbb;
          border-radius: 8px;
          background-color: transparent;
          margin-top: 1.2rem;
          cursor: pointer;
          outline: none;
          font-size: 0.9rem;
          &.active {
            color: #1c69d4;
            border: 1px solid #1c69d4;
          }
        }
      }
      .login-btn {
        width: 100%;
        height: 3.2rem;
        line-height: 3.2rem;
        background-color: #1c69d4;
        color: #fff;
        border: none;
        border-radius: 8px;
        font-weight: bold;
        cursor: pointer;
        outline: none;
        font-size: 0.9rem;
        &.disabled {
          cursor: not-allowed;
        }
        &:hover {
          background-color: #124994;
        }
      }
    }
    .right {
      width: 27%;
      height: auto;
    }
  }
}
